import React from 'react';
import { Link } from "react-router-dom";
import Survey from './Survey'
import SurveyContext from './SurveyContext';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

class QuestionInputText extends React.Component{
    render(){
        return(
            <SurveyContext.Consumer>
                { context => (
                    <div className="" >                
                        
                        <div className="row">
                            <div className="col-md-12">
                                <label>
                                    <FormattedMessage
                                        id="SURVEY_EMAIL_TITLE"
                                        defaultMessage="Would you like to answer more questions? Enter your email so we can contact you"
                                    />
                                </label>
                                <input data-question-id={this.props.question_id} type="text" name="text-value" className="form-control" placeholder="E-mail" onChange={context.handleQInput}/>                        
                            </div>
                        </div>
                    </div>
                )}
            </SurveyContext.Consumer>
        )        
    }
}

export default QuestionInputText;