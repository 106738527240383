import React from 'react';
import Modal from 'react-bootstrap/Modal'

class ReportPhotos extends React.Component {
	
	constructor(props, context) {
		super(props, context)
		this.state = {
		  show: false
		}
	}

	setShow(v){
		this.setState({show: v})
	}

	render(){

		const handleClose = () => this.setShow(false);
		const handleShow = () => this.setShow(true);

		const items = []

		if (this.props.report.photos) {
			for (const [index, photo] of this.props.report.photos.entries()) {
				items.push(photo.link)
			}				
		}

		let photos = ""
		if (this.props.report && this.props.report.photos) {
			{this.props.report.photos.map((photo, index) => {
				photos =  
							<div>
								<img src={photo.link} alt="Click to zoom" className="img-thumbnail" onClick={handleShow}></img>

								<Modal show={this.state.show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
								
									<Modal.Body>
										<img src={photo.link} alt="Click to zoom" className="img-thumbnail"/>	
									</Modal.Body>									
								</Modal>
								
							</div>	
									
								
			})};	
		}

		return (
			<div>
				{photos}
			</div>
		
		);
	}
}

export default ReportPhotos;