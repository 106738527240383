import React from 'react';
import SurveyContext from './SurveyContext';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { store } from 'react-notifications-component';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

class SurveyProvider extends React.Component {
    
    state = {
        q1: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q2: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q3: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q4: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q5: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q6: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q7: {         
            isA: false,
            isB: false,
            isC: false,
            isD: false,
            isE: false,
            isF: false,
            isSet: false
        },
        q8: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q9: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q10: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q11: {
            isYes: false,
            isNo: false,
            isSet: false
        },
        q12: {       
            isA: false,
            isB: false,
            isC: false,
            isD: false,
            isE: false,
            isF: false,
            isSet: false     
        },
        q13: {       
            isA: false,
            isB: false,
            isC: false,
            isD: false,
            isE: false,
            isSet: false     
        },
        q14: {
            value: ""
        },
        q15: {
            value: ""
        },
        map: {
            isSet: false,
            isMapChange: true,
            isMovedAtleastOnce: false,
            map: null,
            zoom: 5,
            latitude: 57.7850918,
            longitude: 15.9319117
        }        
    }

    handleQYesNo = (item) => {
        var question_id = item.target.getAttribute("data-question-id");
        var question_key = "q"+question_id

        if(item.target.id == "btn-q-yes" && this.state[question_key].isYes == false) {
            this.setState({[question_key]: {isYes: true, isNo: false, isSet: true}});
        }
        else if(item.target.id == "btn-q-no" && this.state[question_key].isNo == false) {
            this.setState({[question_key]: {isYes: false, isNo: true, isSet: true}});
        }
        else if(item.target.id == "btn-q-yes" && this.state[question_key].isYes == true) {
            this.setState({[question_key]: {isYes: false, isNo: false, isSet: false}});
        }
        else if(item.target.id == "btn-q-no" && this.state[question_key].isNo == true) {
            this.setState({[question_key]: {isYes: false, isNo: false, isSet: false}});
        }
        
        item.target.blur();                
        item.preventDefault();        
    }

    handleQFacilities = (item) => {
        var question_id = item.target.getAttribute("data-question-id");
        var question_key = "q"+question_id
        
        let state_item = this.state[question_key]   

        if( item.target.id == "btn-q-a" ) {
            state_item.isA = !state_item.isA
        }
        else if( item.target.id == "btn-q-b" ) {
            state_item.isB = !state_item.isB
        }
        else if( item.target.id == "btn-q-c" ) {
            state_item.isC = !state_item.isC
        }
        else if( item.target.id == "btn-q-d" ) {
            state_item.isD = !state_item.isD
        }
        else if( item.target.id == "btn-q-e" ) {
            state_item.isE = !state_item.isE
        }
        else if( item.target.id == "btn-q-f" ) {
            state_item.isF = !state_item.isF
        }
        this.setState({[question_key]: state_item});

        item.target.blur();        
        item.preventDefault();
    }

    handleQRole = (item) => {
        var question_id = item.target.getAttribute("data-question-id");
        var question_key = "q"+question_id
        
        let state_item = this.state[question_key]   

        if( item.target.id == "btn-q-a" ) {
            state_item.isA = !state_item.isA
        }
        else if( item.target.id == "btn-q-b" ) {
            state_item.isB = !state_item.isB
        }
        else if( item.target.id == "btn-q-c" ) {
            state_item.isC = !state_item.isC
        }
        else if( item.target.id == "btn-q-d" ) {
            state_item.isD = !state_item.isD
        }
        else if( item.target.id == "btn-q-e" ) {
            state_item.isE = !state_item.isE
        }
        this.setState({[question_key]: state_item});

        item.target.blur();        
        item.preventDefault();
    }

    handleQInput = (item) => {
        var question_id = item.target.getAttribute("data-question-id");
        var question_key = "q"+question_id
        
        let state_item = this.state[question_key]   

        state_item.value = item.target.value;

        this.setState({[question_key]: state_item});

        item.preventDefault();
    }

    handleSubmit = (item) => {
        if(this.isValid()){
            this.sendData()
        }
    }

    isValid = () => {        
        if(this.state.q1.isSet && this.state.q1.isYes)  {
            if(!this.state.q2.isSet){
                this.handleMissingAnswer("Fråga nummer 3 är inte besvarad.")
                return false;
            }
            else if(!this.state.q3.isSet){
                this.handleMissingAnswer("Fråga nummer 4 är inte besvarad.")
                return false;
            }
            else if(!this.state.q4.isSet){
                this.handleMissingAnswer("Fråga nummer 5 är inte besvarad.")
                return false;
            }
            else if(!this.state.q5.isSet){
                this.handleMissingAnswer("Fråga nummer 6 är inte besvarad.")
                return false;
            }
            else if(!this.state.q6.isSet){
                this.handleMissingAnswer("Fråga nummer 7 är inte besvarad.")
                return false;
            }
        }
        else if(this.state.q1.isSet && this.state.q1.isNo)  {
            if(!this.state.q8.isSet){
                this.handleMissingAnswer("Fråga nummer 3 är inte besvarad.")
                return false;
            }
            else if(!this.state.q9.isSet){
                this.handleMissingAnswer("Fråga nummer 4 är inte besvarad.")
                return false;
            }
            else if(!this.state.q10.isSet){
                this.handleMissingAnswer("Fråga nummer 5 är inte besvarad.")
                return false;
            }
            else if(!this.state.q11.isSet){
                this.handleMissingAnswer("Fråga nummer 6 är inte besvarad.")
                return false;
            }
        }

        if(!this.state.q13.isA && !this.state.q13.isB && !this.state.q13.isC && !this.state.q13.isD && !this.state.q13.isE){
            this.handleMissingAnswer("Välja någon eller flera roller du har. ")
            return false;
        }

        return true;
    }
    handleMissingAnswer = (message) => {
        store.addNotification({
            title: "Svara på alla frågor som är krav att svara på.",
            message: message,
            type: "danger",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
    }

    handleNotificationSuccess = () => {
        store.addNotification({
            title: "Dina svar är sparade.",
            message: "Tack för att du svarat på dessa frågor. Sveriges Åkeriföretag och Trafikverket kan återkomma till dig med kompletterande frågor.",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
    }

    navigateToThankYou(){
        this.props.history.push('/survey_done')
    }

    handleMapConfirm = (item) => {
        
        console.log("Map confirm"); 

        let state_map = this.state.map;
        state_map.isSet = !state_map.isSet;
        state_map.isMapChange = !state_map.isSet;
        state_map.zoom = this.state.map.map.getZoom();

        this.setState({["map"]: state_map});

        if (state_map.isMapChange) {            
            this.state.map.map.dragging.enable();
            this.state.map.map.doubleClickZoom.enable();
            if (this.state.map.map.zoomControl!=null) {
                this.state.map.map.zoomControl.enable();                
            }
        }
        else{
            this.state.map.map.dragging.disable();
            this.state.map.map.doubleClickZoom.disable();
            if (this.state.map.map.zoomControl!=null) {
                this.state.map.map.zoomControl.disable();                
            }            
        }
    }

    onMapMoved = (e) => {
        let state_map = this.state.map;
        state_map.latitude = this.state.map.map.getCenter().lat;
        state_map.longitude = this.state.map.map.getCenter().lng;
        state_map.longitude = this.state.map.map.getCenter().lng;

        state_map.isMovedAtleastOnce = true;

        this.setState({["map"]: state_map});        
    }

    setMap = (map) => {
        console.log("map changed");

        let state_map = this.state.map;
        state_map.map = map;

        this.setState({["map"]: state_map});
    
        map.on('move', this.onMapMoved);
    }; 

    sendData = (values)=>{
        
        axios.interceptors.request.use(request => {
            console.log('Starting Request: ', request)
            return request
          })

        let data = {
            survey: {
                
            }
        };

        if(this.state.q1.isYes) {
            data["survey"]["restarea_type"] = 1
        }else if(this.state.q1.isNo) {
            data["survey"]["restarea_type"] = 2
        }
        
        if(this.state.q1.isYes) {
            if(this.state.q2.isYes) {
                data["survey"]["are_there_restareas"] = 1
            }else if(this.state.q2.isNo) {
                data["survey"]["are_there_restareas"] = 2
            }
    
            if(this.state.q3.isYes) {
                data["survey"]["difficult_to_get_spot"] = 1
            }else if(this.state.q3.isNo) {
                data["survey"]["difficult_to_get_spot"] = 2
            }
    
            if(this.state.q4.isYes) {
                data["survey"]["day_rest"] = 1
            }else if(this.state.q4.isNo) {
                data["survey"]["day_rest"] = 2
            }
    
            if(this.state.q5.isYes) {
                data["survey"]["daily_rest"] = 1
            }else if(this.state.q5.isNo) {
                data["survey"]["daily_rest"] = 2
            }
    
            if(this.state.q6.isYes) {
                data["survey"]["are_facilities"] = 1
            }else if(this.state.q6.isNo) {
                data["survey"]["are_facilities"] = 2
            }
    
            if(this.state.q6.isYes) {
                if(this.state.q7.isA) {
                    data["survey"]["is_facility_entry_exist"] = 1
                }
                if(this.state.q7.isB) {
                    data["survey"]["is_facility_fencing"] = 1
                }
                if(this.state.q7.isC) {
                    data["survey"]["is_facility_camera"] = 1
                }
                if(this.state.q7.isD) {
                    data["survey"]["is_facility_lighting"] = 1
                }
                if(this.state.q7.isE) {
                    data["survey"]["is_facility_guard"] = 1
                }
                if(this.state.q7.isF) {
                    data["survey"]["is_facility_other"] = 1
                }
            }
        }
        else if(this.state.q1.isNo) {
                
            if(this.state.q8.isYes) {
                data["survey"]["difficult_to_get_spot"] = 1
            }else if(this.state.q8.isNo) {
                data["survey"]["difficult_to_get_spot"] = 2
            }
    
            if(this.state.q9.isYes) {
                data["survey"]["day_rest"] = 1
            }else if(this.state.q9.isNo) {
                data["survey"]["day_rest"] = 2
            }
    
            if(this.state.q10.isYes) {
                data["survey"]["daily_rest"] = 1
            }else if(this.state.q10.isNo) {
                data["survey"]["daily_rest"] = 2
            }
    
            if(this.state.q11.isYes) {
                data["survey"]["are_facilities"] = 1
            }else if(this.state.q11.isNo) {
                data["survey"]["are_facilities"] = 2
            }
    
            if(this.state.q11.isYes) {
                if(this.state.q12.isA) {
                    data["survey"]["is_facility_entry_exist"] = 1
                }
                if(this.state.q12.isB) {
                    data["survey"]["is_facility_fencing"] = 1
                }
                if(this.state.q12.isC) {
                    data["survey"]["is_facility_camera"] = 1
                }
                if(this.state.q12.isD) {
                    data["survey"]["is_facility_lighting"] = 1
                }
                if(this.state.q12.isE) {
                    data["survey"]["is_facility_guard"] = 1
                }
                if(this.state.q12.isF) {
                    data["survey"]["is_facility_other"] = 1
                }
            }
        }
        
        if(this.state.q13.isA) {
            data["survey"]["is_role_driver"] = 1
        }
        if(this.state.q13.isB) {
            data["survey"]["is_role_truck_owner"] = 1
        }
        if(this.state.q13.isC) {
            data["survey"]["is_role_planner"] = 1
        }
        if(this.state.q13.isD) {
            data["survey"]["is_role_transport_manager"] = 1
        }
        if(this.state.q13.isE) {
            data["survey"]["is_role_other"] = 1
        }

        if(this.state.q14.value) {
            data["survey"]["comment"] = this.state.q14.value
        }
        if(this.state.q15.value) {
            data["survey"]["email"] = this.state.q15.value
        }
        if(this.state.map.isSet) {
            data["survey"]["latitude"] = this.state.map.latitude;
            data["survey"]["longitude"] = this.state.map.longitude
        }
        
        let url = "https://reportry.com/api/v2/surveys/create";
        //let url = "http://localhost:3000/api/v2/surveys/create";
        axios.post(url, data )
            .then(response => {
                console.log(response.data);
                this.handleNotificationSuccess()
                this.navigateToThankYou()
            })
            .catch(error => {
                console.log(error);            
            });
    }

    render(){
        const {intl} = this.props;
        return (
            <SurveyContext.Provider
                value={{
                    state: this.state,
                    handleQYesNo: this.handleQYesNo,
                    handleQFacilities: this.handleQFacilities,
                    handleQRole: this.handleQRole,
                    handleQInput: this.handleQInput,
                    handleMapConfirm: this.handleMapConfirm,
                    setMap: this.setMap,
                    handleSubmit: this.handleSubmit
                }}
            >

                {this.props.children}

            </SurveyContext.Provider>
        );
    }
}

//export default SurveyProvider;
export default injectIntl(withRouter(SurveyProvider));
