import React from 'react';
import logo from './../images/live-trafik-icon.png';

class Header extends React.Component {
	
	render(){
		return (
		
			<header>
				<nav className="navbar navbar-default navbar-static-top white-primary shadow-sm">
					<div className="container">
						<div className="row" style={{width: "100%"}}>
							<div className="col-md-12">
								<h2 className="header-color home-link" >
									<a href="/" >
										<img src={logo} className="App-logo mr-2" width="50px" alt='logo' />
										Live Trafik
									</a>	
								</h2>
							</div>
			
						</div>

					</div>
				</nav>
			</header>
		
		);
	}
	
}

export default Header;