import React from 'react';
import { Link } from "react-router-dom";
import Survey from './Survey'
import SurveyContext from './SurveyContext';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

class QuestionRole extends React.Component{

    render(){
        return(
            <SurveyContext.Consumer>
                { context => (
                    <div className="" >                
                        <h5>{this.props.question_text}</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <button data-question-id={this.props.question_id}  id="btn-q-a" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isA ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQRole}>
                                    <FormattedMessage
                                        id="SURVEY_Q_ROLE_A"
                                        defaultMessage="Förare"
                                    />
                                </button>
                                <button data-question-id={this.props.question_id}  id="btn-q-b" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isB ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQRole}>
                                    <FormattedMessage
                                            id="SURVEY_Q_ROLE_B"
                                            defaultMessage="Åkeriägare"
                                        />
                                </button>
                                <button data-question-id={this.props.question_id}  id="btn-q-c" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isC ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQRole}>
                                    <FormattedMessage
                                            id="SURVEY_Q_ROLE_C"
                                            defaultMessage="Planerare"
                                        />
                                </button>
                                <button data-question-id={this.props.question_id}  id="btn-q-d" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isD ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQRole}>
                                    <FormattedMessage
                                            id="SURVEY_Q_ROLE_D"
                                            defaultMessage="Transportledare"
                                        />
                                    </button>
                                <button data-question-id={this.props.question_id}  id="btn-q-e" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isE ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQRole}>
                                    <FormattedMessage
                                                id="SURVEY_Q_ROLE_F"
                                                defaultMessage="Övrig"
                                    />
                                </button>

                            </div>
                        </div>

                    </div>
                )}
            </SurveyContext.Consumer>
        )        
    }
}

export default QuestionRole;