import React from 'react';
import { Link } from "react-router-dom";
import Survey from './Survey'
import SurveyContext from './SurveyContext';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

class QuestionYesNo extends React.Component{
    constructor(props) {
        super(props);        
    }    

    render(){
        return(
            <SurveyContext.Consumer>
                { context => (
                    <div className="">
                                
                        <h5>{this.props.question_text}</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <button data-question-id={this.props.question_id} id="btn-q-yes" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isYes ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQYesNo}>
                                    <FormattedMessage
                                        id="SURVEY_YES"
                                        defaultMessage="Yes"
                                    />
                                </button>
                                <button data-question-id={this.props.question_id} id="btn-q-no" className={`btn mt-2 ${context.state["q"+this.props.question_id].isNo ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQYesNo}>
                                    <FormattedMessage
                                        id="SURVEY_NO"
                                        defaultMessage="No"
                                    />
                                </button>
                            </div>
                        </div>

                    </div>
                )}
            </SurveyContext.Consumer>
        )        
    }
}

export default QuestionYesNo;