import React from 'react';
import axios from 'axios';

import PasswordingLoading from './rest_password/PasswordLoading'
import PasswordInvalidCode from './rest_password/PasswordInvalidCode'
import PasswordSet from './rest_password/PasswordSet'
import PasswordSetSuccessfully from './rest_password/PasswordSetSuccessfully'

class ResetPassword extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            isShowLoading: true,
            isShowInvalidCode: false, 
            isShowSuccess: false,
            isShowSet: false,
            username: 'somename',
            errorMessage: ''
        }
	    
		this.reset_code = props.match.params.resetCode;
		//this.setState({isShowLoading: true});
		this.getData()		
    }
    
    changePassword = (values)=>{
        //alert("changing password: " + values)
        this.setState({isShowLoading: true});
        this.setState({isShowSet: false}); 
        
        let axiosConfig = {
            
                'Content-Type': 'application/x-www-form-urlencoded'
            
        };

        let data = {
            resetCode: this.reset_code,
            password: values["password"],
            repeat_password: values["password"]
        };

        axios.interceptors.request.use(request => {
            console.log('Starting Request 1', request)
            return request
          })

        var bodyData = new FormData();
        bodyData.set("resetCode", this.reset_code);
        bodyData.set("password", values["password"]);
        bodyData.set("repeat_password", values["password"]);


        axios.post('https://reportry.com/api/v1/set_reset_password', bodyData )
          .then(response => {
            console.log(response.data);

            if(response.data.status){
                this.setState({isShowSuccess: true});
            }
            else{
                this.setState({errorMessage: response.data.message})
                this.setState({isShowSet: true});
            }
            this.setState({isShowLoading: false});
          })
          .catch(error => {
            console.log(error);
            this.setState({isShowSet: true});
            this.setState({isShowLoading: false});
          });
             
          
    }

    getData(){

		axios.get("https://reportry.com/api/v1/reset_password_code_check", {params: {checkCode: this.reset_code}})
      	.then(res => {
            this.setState({isShowLoading: false});
                        
            if (res.data.status){
                this.setState({isShowSet: true});
                this.setState({username: res.data.username})
                

            }else{
                this.setState({isShowSet: false});
                this.setState({isShowInvalidCode: true});
            }
        	//const reportData = res.data;
			//this.setState({ report: reportData, isReport: true, isLoading: false });
			//console.log(this.state.report)
		  })
		  .catch(error => {
            this.setState({isShowLoading: false});
			console.log("e:"+error)
			//this.setState({ isReportInvalid: true, isLoading: false });
		  })
	}
    
    render(){
        return(
            <section className="bg-light-grey">
               
                    <div className="container py-4">
                        <div className="row ">
                        
			                <div className="col-md-8 offset-md-2 report-box ">
                                {this.state.isShowLoading
                                    ? <PasswordingLoading /> : ""
                                }
                                
                                { this.state.isShowInvalidCode
                                    ? <PasswordInvalidCode /> : ""
                                }

                                { this.state.isShowSuccess
                                    ? <PasswordSetSuccessfully /> : ""
                                }

                                { this.state.isShowSet
                                    ? <PasswordSet username={this.state.username} changeFunc={this.changePassword} errorMessage={this.state.errorMessage}/> : ""
                                }

                            </div>
                        </div>
                    </div>
                
            </section>
        )
        
    }
}

export default ResetPassword;