import React from 'react';
import {  Formik, Form, Field, ErrorMessage } from 'formik';
import { object, ref, string } from 'yup'
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';


class PasswordSet extends React.Component{


    constructor(props) {
	    super(props);	    
        this.username = props.username;
        this.errorMessage = props.errorMessage;
        
        const isError = false;
    }

    render(){
        
        let errorView;

        if ( this.errorMessage.length > 0 ) {
            errorView = 
                <div className="alert alert-danger mb-4" role="alert">
                    {this.errorMessage}
                </div>                    
        }
        return(
            <div className="row py-4">
                <div className="col-md-6 offset-md-3">
                    <h5 className="mb-4">
                        <FormattedMessage
                                            id="PASSWORD_CHANGE_PASSWORD_FOR"
                                            defaultMessage="Change password for "
                                        />
                        '{this.props.username}'</h5>						 

                    {errorView}                                        

                    <Formik
                        initialValues={{ password: '', passwordConfirm: '' }}
                        validate={values => {
                            const errors = {};
                            if (!values.password) {
                                errors.password = 'Password is required';
                            } else if (values.password.length <= 5) {
                                errors.password = 'Password should be at least 6 characters long';
                            }
                            else if (values.password != values.passwordConfirm) {
                                errors.passwordConfirm = 'Repeat password does not match password';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.props.changeFunc(values);
                        }}
                        >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <Field type="password" name="password" className="form-control form-control-sm " placeholder="New password" />
                                        <ErrorMessage name="password" component="div" className="mt-2 small ml-1 text-danger" />
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <Field type="password" name="passwordConfirm" className="form-control form-control-sm" placeholder="Confirm new password" />
                                        <ErrorMessage name="passwordConfirm" component="div" className="mt-2 ml-1 small text-danger"/>
                                    </div>
                                    <div className="col-md-12 text-right mt-4">
                                        <button type="submit" disabled={isSubmitting} className="btn btn-danger btn-sm"  >
                                        <FormattedMessage
                                            id="PASSWORD_CHANGE_BUTTON"
                                            defaultMessage="Change Password"
                                        />
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    
                </div>
            </div>
            
        )
        
    }
}

export default PasswordSet;