import React from 'react';
import { Link } from "react-router-dom";
import Survey from './Survey'
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import Fade from 'react-reveal/Fade';

class LandingPageSurveyBar extends React.Component{
    render(){
        return(
            <Fade cascade collapse>
                <div className="bg-success p-3 text-white">
                
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8 text-left ">
                                <p className="m-0">
                                    <FormattedMessage
                                        id="HOME_SURVEY_DESCRIPTION"
                                        defaultMessage="Do you care about the rest areas in Sweden? Help making them better, fill out our survey"
                                    /> 
                                    </p>
                            </div>

                            <div className="col-md-4 text-right ">
                                <Link to="/survey" className="btn btn-outline-light p-3" style={{fontWeight: 700}}>
                                    
                                    <FormattedMessage
                                        id="HOME_SURVEY_BUTTON"
                                        defaultMessage="Start the Survey"
                                    /> 
                                </Link>                            
                            </div>
                        </div>
                    </div>
               
                </div>
            </Fade>

        )
        
    }
}

export default LandingPageSurveyBar;