import React from 'react';
import { Link } from "react-router-dom";
import Survey from './Survey'

import QuestionYesNo from './QuestionYesNo'
import QuestionFacilities from './QuestionFacilities'
import OptionalFields from './OptionalFields'
import SurveyContext from './SurveyContext';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

class TrackOne extends React.Component{    
    constructor(props) {
        super(props);
        this.state = {
            isShowQuestion6Yes: false,
            isShowQuestion11Yes: false,
            question_id: this.props.question_id
        }
    }
        
    changeQuestion= (item_state)=>{        
        if(item_state.question_id == 6 ){
            this.setState({isShowQuestion6Yes: item_state.isQYes});
        }
        if(item_state.question_id == 11 ){
            this.setState({isShowQuestion11Yes: item_state});
        }
    }
    
    render(){
        const {intl} = this.props;

        return(
            <div className="" style={{"paddingTop": 20, "paddingBottom": 40, "backgroundColor": "#f2f2f2"}}>
                           
                <div className="card">
                    <div className="card-body">
                        
                        <QuestionYesNo question_id="2" question_text={intl.formatMessage({ id: "SURVEY_TRACK1_Q2" })}/>

                        <div style={{"paddingTop": 40}}/>

                        <QuestionYesNo question_id="3" question_text={intl.formatMessage({ id: "SURVEY_TRACK1_Q3" })}/>

                        <div style={{"paddingTop": 40}}/>

                        <QuestionYesNo question_id="4" question_text={intl.formatMessage({ id: "SURVEY_TRACK1_Q4" })}/>

                        <div style={{"paddingTop": 40}}/>

                        <QuestionYesNo question_id="5" question_text={intl.formatMessage({ id: "SURVEY_TRACK1_Q5" })}/>

                        <div style={{"paddingTop": 40}}/>

                        <QuestionYesNo question_id="6" changeFunc={this.changeQuestion} question_text={intl.formatMessage({ id: "SURVEY_TRACK1_Q6" })}/>

                        <SurveyContext.Consumer>
                            { context => (
                                <div className="">
                                    { context.state.q6.isYes
                                        ? <QuestionFacilities question_id="7" question_text={intl.formatMessage({ id: "SURVEY_TRACK1_Q7" })}/> : ""
                                    }
                                </div>
                            )}
                        </SurveyContext.Consumer>
                    </div>
                                
                </div>
                <div style={{"paddingTop": 20}}/>
                <OptionalFields />
            </div>
        )        
    }
}

export default injectIntl(TrackOne);