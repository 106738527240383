import React from 'react';
import axios from 'axios';

import ReportMap from './ReportMap.js'
import ReportPhotos from './ReportPhotos.js'

import google_play from './../images/google_play.png';
import appstore from './../images/appstore.png';
import spinner from './../images/spinner.gif';
import Moment from 'react-moment';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

class Report extends React.Component {
	
	state = {
		report: "",
		isReport: false,
		isReportInvalid: false, 
		isLoading: true
	}

	constructor(props) {
	    super(props);
	    
		this.reportId = props.match.params.id;
		
		this.getData()
		
	}

	
	getData(){

		axios.get("https://reportry.com/api/v1/reports/show", {params: {report_id: this.reportId}})
      	.then(res => {
        	const reportData = res.data;
			this.setState({ report: reportData, isReport: true, isLoading: false });
			//console.log(this.state.report)
		  })
		  .catch(error => {
			console.log(error)
			this.setState({ isReportInvalid: true, isLoading: false });
		  })
	}
	
	render(){
		let validity_end;
		if (this.state.report.validity_end != 0){
			validity_end = <Moment unix format="YYYY-MM-DD @ HH:mm">{this.state.report.validity_end}</Moment>
		}else{
			validity_end = "-"
		}

		let custom_reports = ""

		if (this.state.report && this.state.report.custom_reports) {
			this.state.report.custom_reports.forEach(function (custom_report, index) {
				custom_reports =  
								<div>
									{custom_reports}	
									<span className="key-value">{custom_report.cr_name} </span>
									<p>{custom_report.cr_type == "checkbox" ?  custom_report.cr_value== true ? "Ja":"Nej": custom_report.cr_value}</p>
									<br/>
								</div>	
			});	
		}
		

		return (
<section className="bg-light-grey">
	<div className="container">
		<div className="row">
			<div className="col-md-8 offset-md-2 report-box">
				
				<div className="text-center" style={{display: this.state.isReportInvalid ? 'block':'none'}}>
					<h2 className="mt-2">
						<FormattedMessage
							id="REPORT_NOT_FOUND"
							defaultMessage="Report is not found"
							/>
					</h2>
				</div>
				
				<div style={{display: this.state.isLoading ? 'block':'none'}}>
					<img src={spinner} className="spinner" alt="loading..."/>
				</div>	
				
				<div  style={{display: this.state.isReport ? 'block':'none'}}>
					<div className="row report-map"> 
						<div className="col-md-12 text-center">
							{this.state.isReport ? <ReportMap report={this.state.report}/>: null}
							
						</div>
					
					</div>
					
					<div>
						<div className="row report-title-row">
							<div className="col-md-8">
								<h2 id="report-title">
									<img src={this.state.report.layer_icon} width="36px" alt="" className="mr-3"/>
									{this.state.report.layer_name}
								</h2>
							</div>
						
							<div className="col-md-4" ng-include="'report/report-share.html'">
								
							</div>
					
						</div>
					
						<div className="row report-text">
							<div className="col-md-8">							
								<span className="key-value" translate="REPORT_ADDRESS">
									<FormattedMessage
													id="REPORT_ADDRESS"
													defaultMessage="Address "
													/>
								</span>
								<p>{this.state.report.address}</p>
								<br/>
					
								<span className="key-value" translate="REPORT_DESCRIPTION"> 
									<FormattedMessage
													id="REPORT_DESCRIPTION"
													defaultMessage="Description "
													/>
								</span>
								<p>
									{this.state.report.description}
								</p>	
								<br/>
					
								{custom_reports}
					
								<div className="row">
									<div className="col-md-6">
										<span className="key-value" translate="REPORT_VALIDITY_STARTS"> 
											<FormattedMessage
													id="REPORT_VALIDITY_STARTS"
													defaultMessage="Validity Starts "
													/>
										</span>
										<p>
											<Moment unix format="YYYY-MM-DD @ HH:mm">{this.state.report.validity_start}</Moment>
										</p>
									</div>
					
									<div className="col-md-6">
										<span className="key-value" translate="REPORT_VALIDITY_ENDS">
											<FormattedMessage
												id="REPORT_VALIDITY_ENDS"
												defaultMessage="Validity Ends"
												/>
										</span>
										<p>											
											{validity_end}
										</p>
										<br/>
									</div>
								</div>
								<br/>										
								<span className="key-value"> Added on </span>
								<p>
									
									<Moment unix format="YYYY-MM-DD @ HH:mm">{this.state.report.created_on}</Moment>
								</p>	
								
								<br/>										
								<span className="key-value" translate="REPORT_SOURCE"> 
									<FormattedMessage
													id="REPORT_SOURCE"
													defaultMessage="Source "
													/>
								</span>
								<p>
									{this.state.report.username}
								</p>
								
							</div>

							<div className="col-md-4">
								<ReportPhotos report={this.state.report} />							
							</div>

						</div>
					</div>	
				</div>	
				
			</div>
			
		</div>
	</div>
	
			
			<div className="row stores-buttons-header">
           
				<div className="col-lg-12 col-md-12 wow fadeIn text-center">
					<a href="https://play.google.com/store/apps/details?id=com.sharj.trafik">
						<img src={google_play} className="img-responsive" alt="Google Play"></img>
					</a>	
					<a href="https://itunes.apple.com/se/app/live-trafik/id428802004?l=en&mt=8">
						<img src={appstore} className="img-responsive" alt="App Store"></img>
					</a>
				</div>
			</div>
	    
	           
</section>

			
		);
	}
	
}

export default Report;