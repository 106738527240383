import React from 'react';
import { withRouter } from "react-router-dom";

class SurveyDone extends React.Component{

    navigateToSurvey = ()=>{
        this.props.history.push('/survey')
    }

    navigateToHome = ()=>{
        this.props.history.push('/')
    }

    render(){
        return(
            <div className="" style={{"paddingTop": 40, "paddingBottom": 40, "backgroundColor": "#f2f2f2"}}>                                
                <div className="row">

                    <div className="col-md-6 offset-md-3">

                        <div className="card">
                            <div className="card-body" style={{"padding": 40}}>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h3>Tack för att du svarat på dessa frågor </h3>
                                        <h5>Sveriges Åkeriföretag och Trafikverket kan återkomma till dig med kompletterande frågor</h5>     
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button id="btn-again" style={{"width": 200}} className={`btn mt-2 mr-3 btn-dark mb-2 mt-4 p-3`} onClick={this.navigateToSurvey} type="button" >Svara igen</button>
                                        <br/>
                                        <button id="btn-again" style={{"width": 200}} className={`btn mt-1 mr-3 btn-outline-secondary c-btn-outline-secondary mt-3 p-3`}  onClick={this.navigateToHome} type="button">Hem</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    
                    
                </div>                    
            </div>
        )
        
    }
}

//export default SurveyDone;
export default withRouter(SurveyDone);
