import React from 'react';
import google_play from './../images/google_play.png';
import appstore from './../images/appstore.png';
import phone_cut from './../images/phones-cut.png';

import logo_akeri from './../images/logo_akeri.png';
import logo_sirt from './../images/logo_sirt.png';
import logo_trafikverket from './../images/logo_trafikverket.png';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

import LandingPageSurveyBar from './survey/LandingPageSurveyBar'

class LandingPage extends React.Component{
	
	render(){
		return (
		
			<div>
					
				<LandingPageSurveyBar/>

				<section className="header">
					<div className="container header-content-inner ">
	
			
						<div className="row">
							<div className="col-md-6 lead-copy text-center">
								<span>
									<h1 className="main">
										<FormattedMessage
											id="HOME_PAGE_TITLE"
											defaultMessage="The real-time traffic system <br/>of Sweden"
											/>
									</h1>						
									<p className="main">
										<FormattedMessage
												id="HOME_PAGE_SUB_TITLE"
												defaultMessage="Download the special mobile app for Roadreporters!"
												/>
									</p>
								</span>
						
								<div className="row stores-buttons">
				           
									<div className="col-lg-12 col-md-12 wow fadeIn text-center">
										<a href="https://play.google.com/store/apps/details?id=com.sharj.trafik">
											<img src={google_play} className="img-responsive" alt="Google Play"></img>
										</a>	
										<a href="https://itunes.apple.com/se/app/live-trafik/id428802004?l=en&mt=8">
											<img src={appstore} className="img-responsive" alt="App Store"></img>
										</a>
									</div>
				
								</div>
						
							</div>
				
							<div className="col-md-6 phone wow fadeIn text-center">
								<img src={phone_cut} className="phone-img img-responsive" alt=""/>
							</div>
				
						</div>
			
					</div>
				</section>

		
				<section className="bg-dark top-section ">
					<div className="container">
						<div className="row text-center">
							<div className="col-md-12 ">
								<h2>
									<FormattedMessage
										id="HOME_PAGE_JOIN_ROADREPORTER"
										defaultMessage="Stay updated, become a Roadreporter"
										/>
								</h2>
								<p >
									<FormattedMessage
										id="HOME_PAGE_JOIN_ROADREPORTER_SUB"
										defaultMessage="Join the community of safe drivers!"
										/>
								</p>
							</div>
						</div>
					</div>
				</section>
			
						
				<section id="services" className="bg-light-grey section-padding icons-primary">
					<div className="container">
			
						<div className="row">
							<div className="col-lg-4 col-md-6 text-center">
								<div className="service-box">
									<i className="fa fa-4x fa-road wow bounceIn  mb-3"></i>
									<h2 translate=""> 
										<FormattedMessage
											id="BOX1_TITLE"
											defaultMessage="All kind of road events"
											/>
									</h2>
									<p > 
										<FormattedMessage
											id="BOX1_TEXT"
											defaultMessage="Check on map or just scroll through posts feed"
											/>
									</p>
								</div>
							</div>
                
							<div className="col-lg-4 col-md-6 text-center">
								<div className="service-box">
									<i className="fa fa-4x fa-plus-circle wow bounceIn  mb-3" data-wow-delay=".1s"></i>
									<h2 translate="BOX2_TITLE">
										<FormattedMessage
											id="BOX2_TITLE"
											defaultMessage="Submit reports"
											/>
									</h2>
									<p translate="BOX2_TEXT">
										<FormattedMessage
											id="BOX2_TEXT"
											defaultMessage="If you notice something unusual, post it to warn other drivers"
											/>
									</p>
								</div>
							</div>
              
							<div className="col-lg-4 col-md-6 text-center">
								<div className="service-box">
									<i className="fa fa-4x fa-diamond wow bounceIn mb-3" data-wow-delay=".2s"></i>
									<h2 translate="BOX3_TITLE">
										<FormattedMessage
												id="BOX3_TITLE"
												defaultMessage="Become Vägrapportör"
												/>
									</h2>
									<p>
										<FormattedMessage
											id="BOX3_TEXT"
											defaultMessage="If you notice something unusual, post it to warn other driversSend reports directly to Trafikverket with Live Trafik"
											/>
									</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 text-center">
								<div className="service-box">
									<i className="fa fa-4x fa-clock-o wow bounceIn  mb-3" data-wow-delay=".1s"></i>
									<h2 translate="BOX4_TITLE">
										<FormattedMessage
												id="BOX4_TITLE"
												defaultMessage="Save time"
												/>
									</h2>

									<p translate="BOX4_TEXT">
										<FormattedMessage
											id="BOX4_TEXT"
											defaultMessage="Being aware of traffic incidents, choose faster route"
											/>
									</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 text-center">
								<div className="service-box">
									<i className="fa fa-4x fa-camera wow bounceIn  mb-3" data-wow-delay=".2s"></i>
									<h2 translate="BOX5_TITLE">
										<FormattedMessage
												id="BOX5_TITLE"
												defaultMessage="Trafikverket cameras"
												/>
									</h2>
									<p translate="BOX5_TEXT">
										<FormattedMessage
											id="BOX5_TEXT"
											defaultMessage="Being aware of traffic incidents, choose faster routeCheck out photos from goverment street cameras"
											/>
									</p>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 text-center">
								<div className="service-box">
									<i className="fa fa-4x fa-fire wow bounceIn  mb-3" data-wow-delay=".3s"></i>
									<h2 translate="BOX6_TITLE">
										<FormattedMessage
												id="BOX6_TITLE"
												defaultMessage="Accidents Heatmap"
												/>
									</h2>
									<p translate="BOX6_TEXT">
										<FormattedMessage
											id="BOX6_TEXT"
											defaultMessage="See the 2-years history of accidents on a heatmap"
											/>
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
	
				<section className="section-padding">
					<div className="container partners">
						<div className="row">
							<div className="col-md-6 offset-md-3 wow fadeIn col-sm-10 offset-sm-1">
								<div className="row">
									<div className="col-md-4 col-sm-4">
										<a href="https://www.trafikverket.se/tjanster/Anmal-skador-och-brister/Appen-Live-Trafik/">
											<img src={logo_trafikverket} className="partner img-responsive" alt="Trafikverket"/>
										</a>	  
									</div>
						
									<div className="col-md-4 text-center col-sm-4">	
										<a href="http://akeri.se">
											<img src={logo_akeri} className="partner img-responsive" alt="Akeriforetag"/>
										</a>		  
									</div>	
					
									<div className="col-md-4 text-center col-sm-4">	
										<a href="http://sirt.se">
											<img src={logo_sirt} alt="SIRT AB" className="partner img-responsive"/>
										</a>
									</div>	
								</div>	
							</div>
						</div>
				      	<br/>		
						<div className="row">
							<div className="col-md-12 text-center">
								
								<h5 translate="HOME_PAGE_COOPERATION">
									<small>
										<FormattedMessage
											id="HOME_PAGE_COOPERATION"
											defaultMessage="Live Trafik är ett samarbete mellan Trafikverket, Sveriges Åkeriföretag och SIRT Sverige AB"
											/>		
									</small> 
								</h5>

							</div>
						</div>
		
					</div>

				</section>
			</div>							
		
		);
	}
	
	
}

export default LandingPage;