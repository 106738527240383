import React from 'react';
import { Link } from "react-router-dom";
import Survey from './Survey'
import SurveyContext from './SurveyContext';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';
class QuestionFacilities extends React.Component{
    
    render(){
        return(
            <SurveyContext.Consumer>
                { context => (
                    <div className="" style={{"paddingLeft": 60}}>
                        <div style={{"paddingTop": 30}}/>
                        <h6>{this.props.question_text}</h6>
                        <div className="row">
                            <div className="col-md-12">
                                <button data-question-id={this.props.question_id} id="btn-q-a" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isA ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQFacilities}>
                                    <FormattedMessage
                                        id="SURVEY_FACILITY_A"
                                        defaultMessage="Reglerad in och utfart"
                                    />
                                </button>
                                <button data-question-id={this.props.question_id} id="btn-q-b" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isB ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQFacilities}>
                                    <FormattedMessage
                                        id="SURVEY_FACILITY_B"
                                        defaultMessage="Instängsling"
                                    />
                                </button>
                                <button data-question-id={this.props.question_id} id="btn-q-c" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isC ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQFacilities}>
                                    <FormattedMessage
                                            id="SURVEY_FACILITY_C"
                                            defaultMessage="Kameraövervakning"
                                        />
                                </button>
                                <button data-question-id={this.props.question_id} id="btn-q-d" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isD ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQFacilities}>
                                    <FormattedMessage
                                            id="SURVEY_FACILITY_D"
                                            defaultMessage="Bättre belysning"
                                        />
                                </button>
                                <button data-question-id={this.props.question_id} id="btn-q-e" className={`btn mt-2 mr-3 ${context.state["q"+this.props.question_id].isE ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQFacilities}>
                                    <FormattedMessage
                                            id="SURVEY_FACILITY_E"
                                            defaultMessage="Vakt"
                                        />
                                </button>
                                <button data-question-id={this.props.question_id} id="btn-q-f" className={`btn mt-2 ${context.state["q"+this.props.question_id].isF ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQFacilities}>
                                    <FormattedMessage
                                            id="SURVEY_FACILITY_F"
                                            defaultMessage="Annat"
                                        />
                                </button>

                            </div>
                        </div>

                    </div>
                )}
            </SurveyContext.Consumer>
        )        
    }
}

export default QuestionFacilities;