import React from 'react';
import { Link } from "react-router-dom";
import Survey from './Survey'
import SurveyContext from './SurveyContext';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

class QuestionMap extends React.Component{

    constructor() {
		super()
		this.state = {          
		}
    }
    
    
        /*
        const mapChanged = useMapEvent('move', (e) => {
            console.log("map changed: "+ map.getCenter().lat);
            /*
            map.setView(e.latlng, map.getZoom(), {
              animate: animateRef.current || false,
            })*/
     

/*
        map.on('move', function(ev) {
            //alert(ev.latlng); // ev is an event object (MouseEvent in this case)
            console.log("map changed: "+ map.getCenter().lat);
            //this.setState(position: map.getCenter())
        });
*/        
    

    render(){
        const {intl} = this.props;
        return(
            <SurveyContext.Consumer>
                { context => (
                    <div className="" style={{"paddingTop": 20, "backgroundColor": "#f2f2f2"}}>
                           
                        <div className="card">
                            <div className="card-body">

                                <div className="">
                                            
                                    <h5>{this.props.question_text}</h5>

                                    <div className="row">
                                        <div className="col-md-12 mt-2">
                                            <MapContainer center={[context.state.map.latitude, context.state.map.longitude]}
                                                          zoom={context.state.map.zoom} 
                                                          scrollWheelZoom={false}
                                                          onMoveEnd={this.onMoveEnd}
                                                          whenCreated={context.setMap}
                                                          dragging={context.state.map.isMapChange}
                                                          doubleClickZoom={context.state.map.isMapChange}
                                                          zoomControl={context.state.map.isMapChange}>
                                                <TileLayer
                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker position={[context.state.map.latitude, context.state.map.longitude]}>
                                                    <Popup>
                                                    
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>		
                                        </div>
                                    </div>

                                    <div className="row mt-2">

                                        <div className="col-md-12 text-muted text-center">
                                        {context.state["map"].isMapChange ? intl.formatMessage({ id: "SURVEY_MAP_HELPER_MOVE_MAP" }): intl.formatMessage({ id: "SURVEY_MAP_HELPER_CHANGE_MAP" }) }
                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-right">
                                            <button id="btn-q-a" className={`btn btn-block  mt-3 p-3 btn-q-1 ${context.state["map"].isSet ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"} ${context.state["map"].isMovedAtleastOnce ? "" : "disabled"}`} type="button" onClick={context.handleMapConfirm} disabled={!context.state.map.isMovedAtleastOnce}>
                                                {context.state["map"].isSet ? intl.formatMessage({ id: "SURVEY_MAP_CONFIRMED_BUTTON" }) : intl.formatMessage({ id: "SURVEY_MAP_CONFIRM_BUTTON" }) }
                                            </button>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                )}
            </SurveyContext.Consumer>
        )        
    }
}

export default injectIntl(QuestionMap);