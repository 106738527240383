import React from 'react';
import { Link } from "react-router-dom";
import Survey from './Survey'
import SurveyContext from './SurveyContext';
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';
class QuestionInputArea extends React.Component{

    render(){
        const {intl} = this.props
        return(
            <SurveyContext.Consumer>
                { context => (
                    <div className="" >                
                        <h5>
                            <FormattedMessage
                                id="SURVEY_COMMENT_TITLE"
                                defaultMessage="Övrig kommentar (optional)"
                            />
                        </h5>
                        <div className="row">
                            <div className="col-md-12">                        
                                <textarea data-question-id={this.props.question_id} name="text-value" className="form-control" placeholder={intl.formatMessage({ id: "SURVEY_COMMENT_TITLE" })} onChange={context.handleQInput}/>                        
                            </div>
                        </div>

                    </div>
                )}
            </SurveyContext.Consumer>
        )        
    }
}

export default injectIntl(QuestionInputArea);