import React from 'react';
import { BrowserRouter as Router, Route, HashRouter, Link } from "react-router-dom";

import Header from './com/Header.js'
import Footer from './com/Footer.js'
import LandingPage from './com/LandingPage'
import Privacy from './com/Privacy'
import Report from './com/Report'
import ResetPassword from './com/ResetPassword'
import Survey from './com/survey/Survey'
import SurveyDone from './com/survey/SurveyDone'
import ReactNotification from 'react-notifications-component'

function App() {
  return (
    <HashRouter>
	    <div className="App">
			<ReactNotification />
	  		<Header />

			  <Route path="/" exact component={LandingPage} />
			  <Route path="/privacy/" component={Privacy} />
			  <Route path="/test/" component={Test} />
		  	  <Route path="/report/:id" component={Report} />	  	  
			  <Route path="/reset_password/:resetCode" component={ResetPassword} />

			  <Route path="/survey/" component={Survey} />
			  <Route path="/survey_done/" component={SurveyDone} />

		    <Footer />
	    </div>
	  </HashRouter>
  );
}

function Test() {
  return <h2>Test</h2>;
}




export default App;
