import React from 'react';

class PasswordInvalidCode extends React.Component{
    render(){
        return(

            <div className="text-center">
				<h4>Password reset code is not valid</h4>
			</div>
        )
        
    }
}

export default PasswordInvalidCode;