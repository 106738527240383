import React, { Component } from 'react';
//import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

class ReportMap extends React.Component{
	
	constructor() {
		super()
		this.state = {
		  zoom: 15
		}
	  }

	render(){
		const position = [this.props.report.latitude, this.props.report.longitude];

		return (
			<MapContainer center={position} zoom={this.state.zoom} scrollWheelZoom={false}>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<Marker position={position}>
					<Popup>
					A pretty CSS3 popup. <br /> Easily customizable.
					</Popup>
				</Marker>
			</MapContainer>			
		)
	}
}

export default ReportMap;