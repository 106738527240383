import React from 'react';
import spinner from './../../images/spinner.gif';

class PasswordLoading extends React.Component{
    render(){
        return(
            <div >
				<img src={spinner} className="spinner" alt="loading..."/>
			</div>	
        )
        
    }
}

export default PasswordLoading;