import React from 'react';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

class PasswordSetSuccessfully extends React.Component{
    render(){
        return(
            <div className="text-center py-4">
				<h4>
                    <FormattedMessage
                        id="PASSWORD_SUCCESS_HEADING"
                        defaultMessage="Your new password is now set"
					/>
                </h4>

                <h5>
                    <FormattedMessage
                            id="PASSWORD_SUCCESS_DESCRIPTION"
                            defaultMessage="Now you can login using your new password in the mobile application."
                        />
                    </h5>
			</div>
        )
        
    }
}

export default PasswordSetSuccessfully;