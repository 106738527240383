import React from 'react';
import { Link } from "react-router-dom";

class Footer extends React.Component{
	
	render(){
		return (
		
			<footer>
				<section className="bg-dark top-section section-padding">
					<div className="container text-center">
						<div className="row">
							<div className="col-md-12 links">
								<a href="https://www.facebook.com/Live-Trafik-202023533209504/?ref=hl" className="top"><i className="fab fa-facebook-f fa-2x wow bounceIn"/></a>
								<a href="http://twitter.com/LiveTrafik" className="top"><i className="fab fa-twitter fa-2x wow bounceIn"/></a>
								<a href="mailto:sharjeel@sirt.com?subject=Live Trafik" className="top"><i className="fa fa-2x fa-envelope wow bounceIn"></i></a>
							</div>
						</div>
					</div>
				</section>

				<div className="copyright text-center">	
					<div className="container ">
						<div className="row">
							<div className="col-lg-12">
								<p className="small">
									<Link to="/privacy" className=" bottom mr-2"> Privacy </Link> 							
									
								</p>	
								<p className="copyright small">Copyright &copy; 
									<a href="http://sirt.se" className="small bottom">SIRT AB </a>
									2018. All Rights Reserved
								</p>
								<a href="https://mixpanel.com/f/partner" rel="nofollow"><img src="//cdn.mxpnl.com/site_media/images/partner/badge_light.png" alt="Mobile Analytics" /></a>
							</div>
						</div>
					</div>
				</div>
			</footer>
		
		);
	}
	
	
}

export default Footer;