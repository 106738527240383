import React from 'react';
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

import TrackOne from './TrackOne'
import TrackTwo from './TrackTwo'
import QuestionRole from './QuestionRole'
import QuestionInputText from './QuestionInputText'
import QuestionInputArea from './QuestionInputArea'
import SurveyContext from './SurveyContext';
class OptionalFields extends React.Component{
    
    render(){
        const {intl} = this.props;
        
        return(
            
            <div className="card">
                <div className="card-body">

                    <QuestionRole question_id="13" question_text={intl.formatMessage({ id: "SURVEY_Q_SELECT_ROLE" })}/>

                    <div style={{"paddingTop": 40}}/>

                    <QuestionInputArea question_id="14" question_text=""/>
                    
                    <div style={{"paddingTop": 40}}/>

                    <QuestionInputText question_id="15" question_text=""/>

                    <SurveyContext.Consumer>
                        { context => (
                            <div className="row mt-4">
                                <div className="col-md-12 text-right">
                                    <button id="btn-save" className={`btn btn-dark p-3`} type="button" onClick={context.handleSubmit}>
                                        <FormattedMessage
                                            id="SURVEY_SUBMIT"
                                            defaultMessage="Send your survey"
                                        />
                                    </button>                            
                                </div>                        
                            </div>
                        )}
                    </SurveyContext.Consumer>
                </div>
            </div>
            
        )        
    }
}

export default injectIntl(OptionalFields);