import React from 'react';
import { Link } from "react-router-dom";
import Survey from './Survey'
import SurveyContext from './SurveyContext';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

import logo_akeri from './../../images/logo_akeri_long.jpg';
import logo_trafikverket from './../../images/logo_trafikverket_long.jpg';

class QuestionTrackSelect extends React.Component{
    render(){
        return(
            <SurveyContext.Consumer>
                { context => (
                    <div className="">
                        <h2>
                            <FormattedMessage
                                id="SURVEY_HEADING"
                                defaultMessage="Survey"
                            />
                        </h2>

                        <p style={{fontSize: "14px"}}>
                            <FormattedMessage
                                id="SURVEY_DESCRIPTION_LONG"
                                defaultMessage="Survey"
                            />
                        </p>
                        <hr/>

                        <div className="row align-items-center">
                            <div className="col-md-6 text-center mt-2">
                                <img src={logo_trafikverket} className="img-thumbnail" alt="Trafikverket" style={{maxWidth: "200px"}} />
                            </div>
                            <div className="col-md-6 text-center mt-2">
                                <img src={logo_akeri} className="img-thumbnail" alt="Sveriges Åkeriföretag" style={{maxWidth: "200px"}}/>
                            </div>
                        </div>
                        
                        <div className="row align-items-center mt-4">
                            <div className="col-md-12 text-center text-muted" style={{fontSize: "14px"}}>
                                Undersökningen är ett samarbete mellan Trafikverket och Sveriges Åkeriföretag
                            </div>                            
                        </div>
                        

                        <hr/>
    
                        <h5>
                            <FormattedMessage
                                    id="SURVEY_Q1_NAME"
                                    defaultMessage="1. Select a track"
                                />
                        </h5>
                        <small className="form-text text-muted">
                            <FormattedMessage
                                id="SURVEY_Q1_HELP"
                                defaultMessage="Select a track that you want to answer"
                            />
                                </small>
                        <div className="row">
                            <div className="col-md-12">
                                <button data-question-id={this.props.question_id} id="btn-q-yes" className={`btn btn-block  mt-3 p-3 btn-q-1 ${context.state["q"+this.props.question_id].isYes ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQYesNo}>
                                    <FormattedMessage
                                        id="SURVEY_Q1_A"
                                        defaultMessage="Var skulle du vilja stanna för vila"
                                    />
                                    
                                </button>
                                <button data-question-id={this.props.question_id} id="btn-q-no" className={`btn btn-block  mt-3 p-3 btn-q-1 ${context.state["q"+this.props.question_id].isNo ? "btn-success" : "btn-outline-secondary c-btn-outline-secondary"}`} type="button" onClick={context.handleQYesNo}>
                                    <FormattedMessage
                                            id="SURVEY_Q1_B"
                                            defaultMessage="Var brukar du stanna för vila"
                                    />
                                </button>
                            </div>
                        </div>    
                    </div>
                )}
            </SurveyContext.Consumer>
        )        
    }
}

export default QuestionTrackSelect;