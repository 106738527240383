import React from 'react';
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import {FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';

import QuestionTrackSelect from './QuestionTrackSelect'
import TrackOne from './TrackOne'
import TrackTwo from './TrackTwo'
import OptionalFields from './OptionalFields'
import SurveyProvider from './SurveyProvider'
import SurveyContext from './SurveyContext';
import { useHistory } from "react-router-dom";
import QuestionMap from './QuestionMap';


class Survey extends React.Component{
    
    render(){
        const {intl} = this.props
        return(
            
                <SurveyProvider>
                    <div className="" style={{"paddingTop": 40, "paddingBottom": 40, "backgroundColor": "#f2f2f2"}}>                                
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <QuestionTrackSelect question_id="1"/>                                 
                                        </div>
                                    </div>

                                    <SurveyContext.Consumer>
                                        { context => (
                                            <div className="">
                                                {context.state.q1.isSet && context.state.q1.isYes
                                                    ? <QuestionMap question_text={intl.formatMessage({ id: "SURVEY_MAP_Q1A_TITLE" })} /> : ""
                                                }

                                                {context.state.q1.isSet && context.state.q1.isNo
                                                    ? <QuestionMap question_text={intl.formatMessage({ id: "SURVEY_MAP_Q1B_TITLE" })} /> : ""
                                                }

                                                {context.state.map.isSet && context.state.q1.isYes
                                                    ? <TrackOne /> : ""
                                                }

                                                {context.state.map.isSet && context.state.q1.isNo
                                                    ? <TrackTwo /> : ""
                                                }
                                            </div>
                                        )}
                                    </SurveyContext.Consumer>
                                </div>
                            </div>
                            
                        </div>                    
                    </div>
                </SurveyProvider>
            
        )        
    }
}

export default injectIntl(Survey);